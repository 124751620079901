<template>
  <b-card title="Reasons">
    <div>
      <b-row>
        <b-col cols="6">
          <!-- input search -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <!-- add new reason -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-reason-add
                  variant="outline-primary"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusCircleIcon" />
                  <span class="align-middle"> Add New Reason</span>
                </b-button>
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <!--      Modal-->
      <b-modal
        id="modal-reason-add"
        ref="modal"
        size="md"
        hide-footer
        title="Add New Reason"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Reason Type"
                label-for="h-reason-type"
                label-cols-md="4"
              >
                <b-form-select
                  id="mc-reason-type"
                  v-model="selected"
                  :options="options"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Reason"
                label-for="h-reason"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-reason"
                  v-model="reason"
                  placeholder="Enter Suitable Reason"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Remark"
                label-for="h-remark"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-remark"
                  v-model="remark"
                  placeholder="Enter Suitable Remark"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearOnClick"
              >
                Clear
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-modal>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'rType'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.rType }}</span>
          </div>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select Reason Type' },
        { value: 'Fail to Deliver', text: 'Fail to Deliver' },
        { value: 'Rescheduled', text: 'Rescheduled' },
        { value: 'Partially Delivered', text: 'Partially Delivered' },
        { value: 'Canceled', text: 'Canceled' },
      ],
      reason: '',
      remark: '',
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Reason',
          field: 'rType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Reason Type',
          },
        },
        {
          label: 'Reason Type',
          field: 'reason',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Reason',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [
        {
          rType: 'Already Delivered',
          reason: 'Failed to Deliver',
        },
        {
          rType: 'Already Purchased',
          reason: 'Failed to Deliver',
        },
        {
          rType: 'Bad Weather',
          reason: 'Rescheduled',
        },
        {
          rType: 'COD already paid in Total',
          reason: 'Partially Delivered',
        },
        {
          rType: 'Customer has already paid partially in advance',
          reason: 'Partially Delivered',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data })
  },
  methods: {
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    clearOnClick() {
      this.selected = null
      this.reason = ''
      this.remark = ''
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
